<!-- @format -->

<template>
  <div>
    <Tabs
      :key="tabsData"
      :tabsData="tabsData"
      :activeTabIndex="tabIndex"
      @index="switchTab"
    />
    <div
      class="relative overflow-x-auto rounded-xl bg-white border border-gray-200 mt-8"
    >
      <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
        <div class="justify-items-start p-4 text-base w-full">
          <p class="pl-2">Manage Threshold</p>
        </div>
      </div>
      <SearchField
        @input="searchPractices"
        placeholder="Search Practice"
        class="border-t border-gray-100"
      />
      <table class="w-full text-sm text-left">
        <thead class="text-sm text-white bg-teal border-b">
          <tr>
            <th scope="col" class="pl-6 py-4 tracking-wider">
              <span v-for="header in tableHeadings" :key="header">{{
                header
              }}</span>
            </th>
          </tr>
        </thead>
        <div
          v-if="loading"
          class="bg-white flex items-center justify-center h-96"
        >
          <svg
            class="text-gray-100 animate-spin"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
          >
            <path
              d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
              stroke="currentColor"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
              stroke="currentColor"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-teal"
            ></path>
          </svg>
        </div>
        <tbody v-else>
          <div v-if="thresholdData?.data?.length == 0">
            <div
              class="flex flex-col items-center justify-center text-center w-full py-10"
            >
              <div class="rounded-full p-12 icon-bg">
                <img src="../../../../assets/icons/noData.svg" class="w-32" />
              </div>
              <div class="mt-4 text-teal font-medium flex items-center">
                <i class="material-icons text-teal w-8">info_outlined</i>
                No Practices Found
              </div>
            </div>
          </div>
          <tr v-else v-for="data in thresholdData?.data" :key="data?.id">
            <div class="border-b border-gray-200">
              <td
                class="py-4 font-medium text-gray-900 overflow-hidden items-center"
              >
                <div>
                  <div class="flex items-center">
                    <button
                      @click="togglePractices(data?.id)"
                      class="pl-6 pr-2"
                    >
                      <span class="material-icons">{{
                        data?.showShifts
                          ? "keyboard_arrow_down"
                          : "keyboard_arrow_up"
                      }}</span>
                    </button>
                    <div class="py-4">{{ data?.practice_name }}</div>
                  </div>
                </div>
              </td>
            </div>
            <div
              v-if="data?.showShifts"
              class="px-4 question-table-bg border-b text-gray-500"
            >
              <div class="flex items-center">
                <button @click="toggleMorning(data?.id)" class="pl-6 pr-2">
                  <span class="material-icons">{{
                    data?.showMorning
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_up"
                  }}</span>
                </button>
                <div class="py-4">Morning 08:00 AM</div>
              </div>
            </div>
            <div v-if="data?.showMorning" class="question-table-bg">
              <table class="w-full text-sm text-left">
                <thead class="text-sm text-black">
                  <tr>
                    <th
                      scope="col"
                      class="px-16 py-4 tracking-wider border-r"
                      :class="index === 1 ? 'text-center  ' : 'w-1/2'"
                      v-for="(header, index) in questionHeadings"
                      :key="header"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="block max-h-520 overflow-y-auto w-full bg-teal bg-opacity-5 hide-scrollbar"
                  :class="
                    data?.thresholdsMorning.length > 5 ? 'h-72' : 'h-full'
                  "
                >
                  <tr
                    v-for="(threshold, index) in data?.thresholdsMorning"
                    :key="index"
                    class="border-t text-gray-500 table w-full"
                  >
                    <td
                      class="py-4 px-16 w-1/2 font-medium tracking-wider border-r border-gray-200"
                    >
                      {{ threshold?.model_day_activity?.activity }}
                    </td>
                    <td
                      class="px-6 py-6 font-medium flex justify-center text-sm text-teal"
                    >
                      <input
                        min="0"
                        max="999"
                        maxlength="3"
                        type="number"
                        step="1"
                        oninput="validity.valid||(value='');"
                        @wheel.prevent=""
                        v-model="threshold.threshold"
                        @input="morningAccept(data?.id)"
                        class="text-center rounded-md border border-teal w-10 focus:border-teal"
                      />
                    </td>
                  </tr>
                  <tr
                    class="border-t border-gray-200 text-gray-500 table w-full"
                  >
                    <td
                      class="py-4 font-medium overflow-hidden items-center border-r border-gray-200 w-1/2"
                    >
                      <div class="px-6 py-2"></div>
                    </td>
                    <td
                      class="px-6 py-6 font-medium flex justify-center text-sm"
                    >
                      <div
                        class="px-3 rounded-md border text-white cursor-pointer"
                        @click="
                          updateThreshold(data?.thresholdsMorning, data?.id)
                        "
                        :class="
                          data?.morningAcceptFlag
                            ? 'border-teal bg-teal'
                            : 'border-gray-500 bg-gray-500 pointer-events-none'
                        "
                      >
                        Approve
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="data?.showShifts"
              class="px-4 question-table-bg border-b text-gray-500"
            >
              <div class="flex items-center">
                <button @click="toggleMidDay(data?.id)" class="pl-6 pr-2">
                  <span class="material-icons">{{
                    data?.showMidDay
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_up"
                  }}</span>
                </button>
                <div class="py-4">Mid-Day 11:00 AM</div>
              </div>
            </div>
            <div v-if="data?.showMidDay" class="question-table-bg">
              <table class="w-full text-sm text-left">
                <thead class="text-sm text-black">
                  <tr>
                    <th
                      scope="col"
                      class="px-16 py-4 tracking-wider border-r"
                      :class="index === 1 ? 'text-center  ' : 'w-1/2'"
                      v-for="(header, index) in questionHeadings"
                      :key="header"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="block max-h-520 overflow-y-auto w-full bg-teal bg-opacity-5 hide-scrollbar"
                  :class="data?.thresholdsMidDay.length > 5 ? 'h-72' : 'h-full'"
                >
                  <tr
                    v-for="(threshold, index) in data?.thresholdsMidDay"
                    :key="index"
                    class="border-t border-gray-200 text-gray-500 table w-full"
                  >
                    <td
                      class="py-4 px-5 font-medium overflow-hidden items-center border-r border-gray-200 w-1/2"
                    >
                      <div class="px-12 py-2">
                        {{ threshold?.model_day_activity?.activity }}
                      </div>
                    </td>
                    <td
                      class="px-6 py-6 font-medium flex justify-center text-sm text-teal"
                    >
                      <input
                        min="0"
                        max="999"
                        type="number"
                        step="1"
                        oninput="validity.valid||(value='');"
                        @wheel.prevent=""
                        v-model="threshold.threshold"
                        @input="midDayAccept(data?.id)"
                        class="text-center rounded-md border border-teal w-10 focus:border-teal"
                      />
                    </td>
                  </tr>
                  <tr
                    class="border-t border-gray-200 text-gray-500 table w-full"
                  >
                    <td
                      class="py-4 font-medium overflow-hidden items-center border-r border-gray-200 w-1/2"
                    >
                      <div class="px-6 py-2"></div>
                    </td>
                    <td
                      class="px-6 py-6 font-medium flex justify-center text-sm"
                    >
                      <div
                        class="px-3 rounded-md border text-white cursor-pointer"
                        @click="
                          updateThreshold(data?.thresholdsMidDay, data?.id)
                        "
                        :class="
                          data?.midDayAcceptFlag
                            ? 'border-teal bg-teal'
                            : 'border-gray-500 bg-gray-500 pointer-events-none'
                        "
                      >
                        Approve
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="data?.showShifts"
              class="px-4 question-table-bg border-b text-gray-500"
              :class="{ 'border-t text-gray-500': data?.showMidDay }"
            >
              <div class="flex items-center">
                <button @click="toggleEvening(data?.id)" class="pl-6 pr-2">
                  <span class="material-icons">{{
                    data?.showEvening
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_up"
                  }}</span>
                </button>
                <div class="py-4">Evening 4:00 PM</div>
              </div>
            </div>
            <div v-if="data?.showEvening" class="question-table-bg">
              <table class="w-full text-sm text-left">
                <thead class="text-sm text-black">
                  <tr>
                    <th
                      scope="col"
                      class="px-16 py-4 tracking-wider border-r"
                      :class="index === 1 ? 'text-center  ' : 'w-1/2'"
                      v-for="(header, index) in questionHeadings"
                      :key="header"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="block max-h-520 overflow-y-auto w-full bg-teal bg-opacity-5 hide-scrollbar"
                  :class="
                    data?.thresholdsEvening.length > 5 ? 'h-72' : 'h-full'
                  "
                >
                  <tr
                    v-for="(threshold, index) in data?.thresholdsEvening"
                    :key="index"
                    class="border-t border-gray-200 text-gray-500 table w-full"
                  >
                    <td
                      class="py-4 px-16 font-medium overflow-hidden items-center border-r border-gray-200 w-1/2"
                    >
                      {{ threshold?.model_day_activity?.activity }}
                    </td>
                    <td
                      class="px-6 py-6 font-medium flex justify-center text-sm text-teal"
                    >
                      <input
                        min="0"
                        max="999"
                        type="number"
                        step="1"
                        oninput="validity.valid||(value='');"
                        @wheel.prevent=""
                        v-model="threshold.threshold"
                        @input="eveningAccept(data?.id)"
                        class="text-center rounded-md border border-teal w-10 focus:border-teal"
                      />
                    </td>
                  </tr>
                  <tr
                    class="border-t border-gray-200 text-gray-500 table w-full"
                  >
                    <td
                      class="py-4 font-medium overflow-hidden items-center border-r border-gray-200 w-1/2"
                    >
                      <div class="px-6 py-2"></div>
                    </td>
                    <td
                      class="px-6 py-6 font-medium flex justify-center text-sm"
                    >
                      <div
                        class="px-3 rounded-md border text-white cursor-pointer"
                        @click="
                          updateThreshold(data?.thresholdsEvening, data?.id)
                        "
                        :class="
                          data?.eveningAcceptFlag
                            ? 'border-teal bg-teal'
                            : 'border-gray-500 bg-gray-500 pointer-events-none'
                        "
                      >
                        Approve
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </tr>
        </tbody>
      </table>
      <Pagination
        :currentPage="thresholdData?.current_page"
        :totalPages="thresholdData.last_page"
        @page-changed="fetchPage"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, watchEffect } from "vue";
import { useStore } from "vuex";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import SearchField from "@/components/newUi/baseComponents/SearchField.vue";
import Swal from "sweetalert2";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import icon from "@/assets/icons/success.svg";

const store = useStore();
const toast = inject("toast");
const tabIndex = ref(0);
const loading = ref(false);

const tabsData = ref([
  {
    name: "England",
    value: 0,
  },
  { name: "Wales", value: 1 },
]);

const switchTab = (index) => {
  tabIndex.value = index;
};

const thresholdData = ref({});
const tableHeadings = ["Practices List"];
const questionHeadings = ref(["Question", "Threshold"]);

watchEffect(async () => {
  loading.value = true;
  const response = await store.dispatch("hqModelDay/fetchThresholds", {
    page: 1,
    region: tabIndex.value == 0 ? "england" : "wales",
  });
  thresholdData.value = response;
  loading.value = false;
  divideThresholds();
});

const divideThresholds = () => {
  for (let i = 0; i < thresholdData.value?.data.length; i++) {
    for (let j = 0; j < thresholdData.value?.data[i]?.thresholds.length; j++) {
      if (thresholdData.value?.data[i]?.thresholds[j]?.shift === 1) {
        // Initialize thresholdsMidDay array if it does not exist
        if (!thresholdData.value.data[i].thresholdsMorning) {
          thresholdData.value.data[i].thresholdsMorning = [];
        }
        thresholdData.value.data[i].thresholdsMorning.push(
          thresholdData.value.data[i].thresholds[j]
        );
      } else if (thresholdData.value?.data[i]?.thresholds[j]?.shift === 2) {
        if (!thresholdData.value.data[i].thresholdsMidDay) {
          thresholdData.value.data[i].thresholdsMidDay = [];
        }
        thresholdData.value.data[i].thresholdsMidDay.push(
          thresholdData.value.data[i].thresholds[j]
        );
      } else {
        // Initialize thresholdsEvening array if it does not exist
        if (!thresholdData.value.data[i].thresholdsEvening) {
          thresholdData.value.data[i].thresholdsEvening = [];
        }
        thresholdData.value.data[i].thresholdsEvening.push(
          thresholdData.value.data[i].thresholds[j]
        );
      }
    }
  }
};

const togglePractices = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showShifts = !rowData.showShifts;
    rowData.showMorning = 0;
    rowData.showMidDay = 0;
    rowData.showEvening = 0;
  }
};
const toggleMorning = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showMorning = !rowData.showMorning;
    rowData.showEvening = 0;
    rowData.showMidDay = 0;
  }
};
const toggleMidDay = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showMidDay = !rowData.showMidDay;
    rowData.showMorning = 0;
    rowData.showEvening = 0;
  }
};
const toggleEvening = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.showEvening = !rowData.showEvening;
    rowData.showMidDay = 0;
    rowData.showMorning = 0;
  }
};
const fetchPage = async (page) => {
  loading.value = true;
  const response = await store.dispatch("hqModelDay/fetchThresholds", {
    page: page,
    region: tabIndex.value == 0 ? "england" : "wales",
  });
  thresholdData.value = response;
  loading.value = false;
  divideThresholds();
};
const searchPractices = async (searchTextValue) => {
  loading.value = true;
  if (searchTextValue) {
    const response = await store.dispatch("hqModelDay/fetchThresholds", {
      practice: searchTextValue,
      region: tabIndex.value == 0 ? "england" : "wales",
    });
    thresholdData.value = response;
    divideThresholds();
  } else {
    const response = await store.dispatch("hqModelDay/fetchThresholds", {
      page: 1,
      region: tabIndex.value == 0 ? "england" : "wales",
    });
    thresholdData.value = response;
    divideThresholds();
  }
  loading.value = false;
};
const updateThreshold = async (thresholdList, id) => {
  const payload = {
    practice: null,
    shift: null,
    thresholds: [],
  };
  let error = false;
  if (thresholdList[0]) {
    payload.practice = thresholdList[0].practice;
    payload.shift = thresholdList[0].shift;
  }
  if (thresholdList) {
    thresholdList.forEach((threshold) => {
      if (
        threshold.threshold === "" ||
        threshold.threshold < 0 ||
        threshold.threshold > 999
      ) {
        toast.show("Please add valid threshold between 0 - 999");
        error = true;
        return;
      }
    });
    thresholdList.forEach((threshold) => {
      const thresholObject = {};
      thresholObject.id = threshold.id;
      thresholObject.threshold = threshold.threshold;
      thresholObject.label = threshold.label;
      payload.thresholds.push(thresholObject);
    });
  }
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.morningAcceptFlag = 0;
    rowData.midDayAcceptFlag = 0;
    rowData.eveningAcceptFlag = 0;
  }
  if (!error) {
    const response = await store.dispatch(
      "hqModelDay/updateThresholds",
      payload
    );
    if (response?.data?.success) {
      Swal.fire({
        toast: true,
        position: "top-end",
        title: "Threshold(s) updated successfully",
        iconHtml: `<img src="${icon}" />`,
        width: "32em",
        customClass: {
          title: "popup-title",
          popup: "popup-border",
          content: "popup-text",
          icon: "popup-icon-border",
        },
        timer: 2000,
        showConfirmButton: false,
        background: `rgba(225, 249, 240, 1)`,
      });
    }
  }
};
const morningAccept = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.morningAcceptFlag = 1;
    rowData.eveningAcceptFlag = 0;
    rowData.midDayAcceptFlag = 0;
  }
};
const midDayAccept = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.midDayAcceptFlag = 1;
    rowData.eveningAcceptFlag = 0;
    rowData.morningAcceptFlag = 0;
  }
};
const eveningAccept = (id) => {
  const rowData = thresholdData.value?.data.find((data) => data.id === id);
  if (rowData) {
    rowData.eveningAcceptFlag = 1;
    rowData.midDayAcceptFlag = 0;
    rowData.morningAcceptFlag = 0;
  }
};
</script>

<style scoped>
.question-table-bg {
  background-color: #f1fcfbb8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

thead {
  @apply table w-full;
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Hide the scrollbar */
}

/* For Edge and IE */
.hide-scrollbar {
  -ms-overflow-style: none; /* Hide the scrollbar */
}

/* Ensure the content is still scrollable */
.hide-scrollbar {
  max-height: 520px; /* Adjust to your desired height */
  overflow-y: scroll; /* Enable vertical scrolling */
}
</style>
